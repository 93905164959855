<script setup lang="ts">
const {cartItems, totalPrice} = useCart();
const mobileSearchOpen = inject("mobileSearchOpen", ref(false));
const {isLoggedIn} = useUser();
const isSideMenuOpened = inject("isSideMenuOpened", ref(false));
const searchActive = inject("searchActive", ref(false));

const cartItemsCount = computed(() => {
    let x = 0;
    cartItems.value.forEach((item) => {
        if (item.type != 'product') {
            return;
        }
        if (item.payload?.customFields?.is_deposit_product == 1) {
            return;
        }
        x += item.quantity;
    });
    return x;
});
function getAccountLink() {
    if (isLoggedIn.value) {
        return "/account";
    }
    return "/login";
}

function openMobileSearch() {
    mobileSearchOpen.value = true;
    isSideMenuOpened.value = false;

    if (process.client) {
        // Somehow it wouldn't focus without a timeout. Likely because it does not exist yet or hidden.
        setTimeout(() => {
            document.getElementById('mainSearchInput').focus();
            searchActive.value = true;
        }, 500);
    }

}

const storeMode = useCookie("mode");
const isb2bMode =  storeMode.value && storeMode.value == 'erhverv';

const cartTotalPrice = computed(() => {
    return isb2bMode ? totalPrice.value * 0.8 : totalPrice.value;
});

</script>

<template>
<LayoutTopBar/>
<div v-if="isSideMenuOpened" class="z-40 fixed inset-0 bg-black opacity-25"/>
<LayoutSideMenu />
<div class="pt-6 xl:pt-8 w-full h-[95px] lg:h-[111px]">
    <div class="fixed z-50 bg-white max-h-[79px] w-full border-b-brand-light border-b border-b-solid" id="page-header">
        <div class="container">

            <div v-if="!mobileSearchOpen" class="mx-auto px-[20px] md:px-0">
                <div class="flex justify-between items-center pb-[16px] pt-[16px]">
                    <div class="flex items-center lg:w-1/4">
                        <button id="store-menu-button"
                                class="w-[39px] h-[39px] xl:w-[46px] xl:h-[46px] bg-brand-lightGold rounded-full border-none p-0 relative"
                                data-test-id="side-menu-open"
                                aria-label="menu" @click="isSideMenuOpened = !isSideMenuOpened">
                            <div id="burger" class="pointer-events-none" :class="{'opened': isSideMenuOpened}">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                        <div class="md:w-1/3 lg:w-1/4">
                            <div class="ml-4 md:mr-0 lg:ml-4 xl:ml-[36px]">
                                <NuxtLink :to="isb2bMode ? '/erhvervskonto/' : '/'" class="flex" @click="isSideMenuOpened = false">
                                    <NuxtImg
                                        class="w-auto lg:h-[31px]"
                                        src="/logo.svg"
                                        alt="Bevco A/S"
                                        height="23"
                                    />
                                </NuxtLink>
                            </div>
                        </div>
                    </div>

                    <div class="hidden md:block w-full md:w-1/2 max-w-[484px]">
                        <LayoutStoreSearch/>
                    </div>

                    <div class="w-[100px] md:w-1/4 pr-2 md:pr-6">
                        <div class="flex items-start justify-between md:justify-end lg:w-[150px] ml-auto mt-[6px]">
                            <div
                                class="relative inline-block flex cursor-pointer w-fit mr-2 md:mr-8 md:hidden"
                                @click="openMobileSearch()"
                            >
                                <NuxtImg
                                    src="/search.svg"
                                    height="18"
                                    alt="Søg"
                                    class="pointer-events-none"
                                />
                            </div>
                            <div class="relative inline-block flex cursor-pointer w-fit mr-2">
                                <NuxtLink :to="getAccountLink()" class="flex flex-col" @click="isSideMenuOpened = false">
                                    <NuxtImg
                                        src="/account.svg"
                                        height="18"
                                        alt="Account"
                                    />
                                    <div class="hidden md:block leading-[15px] mt-[5px]">
                                        <span v-if="isLoggedIn" class="text-xs font-medium">Konto</span>
                                        <span v-else class="text-xs font-medium">{{ $t('account.logIn') }}</span>
                                    </div>
                                </NuxtLink>
                            </div>
                            <NuxtLink
                                class="relative inline-block flex cursor-pointer md:ml-6"
                                data-test-id="header-cart-link"
                                to="/checkout/cart"
                            >
                                <div class="text-center font-sans relative">
                                    <div class="flex flex-col relative">
                                        <NuxtImg
                                            src="/cart.svg"
                                            height="18"
                                            alt="Cart"
                                            class="pointer-events-none"
                                        />
                                        <span
                                            v-if="cartItemsCount > 0"
                                            class="absolute -top-2 bg-brand-primary text-white text-[14px] rounded-full h-2 w-2"
                                            :class="cartItemsCount > 99 ? 'md:w-6 md:h-6 pt-[2px]' : 'md:h-5 md:w-5'"
                                            style="right: -0.5rem"
                                        >
                                            <span class="hidden md:inline-block">{{ cartItemsCount }}</span>
                                        </span>
                                    </div>
                                    <div class="hidden md:block">
                                        <SharedPrice :value="cartTotalPrice" class="text-xs my-0 pt-[6px] font-medium" />
                                    </div>
                                    <div v-if="isb2bMode" class="hidden md:block absolute bottom-[-15px] absolute-x-center ws-nowrap">
                                        <span class="text-xs my-0 pt-[5px] text-brand-darkGray font-medium" >{{ $t('cart.withoutTax') }}</span>
                                    </div>
                                </div>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="mx-auto sm:px-6">
                <div class="flex p-2">
                    <div class="border-r border-r-solid border-gray-200 basis-1/12 flex items-center pr-4">
                        <NuxtImg
                            src="/search.svg"
                            height="26"
                            width="26"
                            alt="Søg"
                        />
                    </div>
                    <div class="grow">
                        <LayoutStoreSearch type="mobile" />
                    </div>
                    <div class="basis-1/12 flex items-center" @click="mobileSearchOpen = false">
                        <img src="/close.svg" height="20" :alt="$t('general.close')" class="pointer-events-none"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <LayoutMenuFlyout />
    </div>
</div>
</template>
<style scoped>
#burger {
    width: 20px;
    height: 13px;
    margin:auto;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

#burger.opened{
    transform: translateY(-3px);
}

#burger > div {
    height: 2px;
    background-color: #000;
    transition: 0.5s;
    z-index: 999;
}

#burger.opened > div:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
}
#burger.opened > div:nth-child(2) {
    opacity: 0;
}
#burger.opened > div:nth-child(3) {
    transform: translateY(-2px) rotate(-45deg);
}
</style>
